import errorsJson from '@errors'

const t = (key: any, params?: any) => {
    let error = (errorsJson as any)[key] ?? key
    Object.keys(params ?? {}).forEach(p => {
        const reg = new RegExp(`\\{\\{\\s*${p}\\s*\\}\\}`, 'g')
        error = error.replace(reg, params[p])
    })
    return error
}

export const getErrors = (errors: any) => {
    let _errors: any[] = []
    let type: 'success' | 'error' | 'info' | 'warning' | undefined = undefined

    if (undefined !== errors && Array.isArray(Object.keys(errors))) {
        Object.keys(errors).forEach((key: string) => {
            if ('promotion-discount-added' === errors[key]?.messageKey) {
                _errors.push({ ...errors[key], message: t('cart_discount', { name: errors[key].message.replace(/Discount /, '').replace(/ has been added/, ''), }), type: 'success' })
                return
            } else if ('product-stock-reached' === errors[key]?.messageKey?.replace(errors[key].id, '')) {
                _errors.push({ ...errors[key], message: t(errors[key].messageKey.replace(errors[key].id, ''), { name: errors[key].name, quantity: errors[key].quantity }), type: 'error' })
                return
            } else if ('promotion-not-found' === errors[key]?.messageKey) {
                _errors.push({ ...errors[key], message: t(errors[key].messageKey, { promotionCode: errors[key].promotionCode }), type: 'error' })
                return
            } else if ('promotion-not-eligible' === errors[key]?.messageKey) {
                _errors.push({ ...errors[key], message: t(errors[key].messageKey, { name: errors[key].name }), type: 'error' })
                return
            } else if ('promotion-excluded' === errors[key]?.messageKey) {
                _errors.push({ ...errors[key], message: t(errors[key].messageKey, { name: errors[key].name }), type: 'error' })
                return
            } else if ('product-not-found' === errors[key]?.messageKey) {
                _errors.push({ ...errors[key], message: t(errors[key].message, { id: errors[key].id }), type: 'error' })
                return
            } else if ('payment-method-blocked' === errors[key]?.messageKey) {
                _errors.push({ ...errors[key], message: t(errors[key].messageKey, { name: errors[key].message.replace(/Payment method /, '').replace(/ not available. Reason: not allowed/, '') }), type: 'error' })
                return
            } else if ('shipping-address-blocked' === errors[key]?.messageKey) {
                const keys = errors[key].key.split('-')
                if (Array.isArray(keys) && 4 === keys.length) {
                    _errors.push({ ...errors[key], message: t(errors[key].messageKey, { value: keys[keys.length - 1] }), type: 'error' })
                    return
                }
            }
            _errors.push({ ...errors[key], message: t(errors[key].message), ...(type ? { type } : {}) })
        })
    }

    return _errors
}


export default t