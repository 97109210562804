const errors = {
    "CHECKOUT__CUSTOMER_CANCELED_EXTERNAL_PAYMENT": "Der Bezahlvorgang wurde durch Ihre Eingabe abgebrochen. Bitte wenden Sie sich an unseren Support.",
    "VIOLATION::CUSTOMER_EMAIL_NOT_UNIQUE": "Die E-Mail-Adresse {{ email }} wird bereits verwendet.",
    "CUSTOMER_EMAIL_NOT_UNIQUE": "Die E-Mail-Adresse {{ email }} wird bereits verwendet.",
    "VIOLATION::TOO_SHORT_ERROR": "Diese Zeichenkette ist zu kurz. Sie sollte mindestens {{ limit }} Zeichen haben.",
    "TOO_SHORT_ERROR": "Diese Zeichenkette ist zu kurz. Sie sollte mindestens {{ limit }} Zeichen haben.",
    "VIOLATION::NO_SUCH_CHOICE_ERROR": "Sie haben einen ungültigen Wert ausgewählt.",
    "FRAMEWORK__RATE_LIMIT_EXCEEDED": "Zu viele Anfragen, versuchen Sie es in {{ seconds }} Sekunden erneut.",
    "CHECKOUT__CUSTOMER_NOT_FOUND": "Es wurde kein passender Kunde für die E-Mail-Adresse {{ email }} gefunden.",
    "CHECKOUT__CUSTOMER_AUTH_BAD_CREDENTIALS": "Ungültiger Benutzername und/oder Passwort.", 
    "NO_SUCH_CHOICE_ERROR": "Sie haben einen ungültigen Wert ausgewählt.",
    "CredentialsSignin": "Bitte überprüfen Sie Ihre Anmeldedaten.",
    "NOT_FOUND": "Nicht gefunden",
    "Not Found": "Nicht gefunden",
    "TOO_MANY_REQUESTS": "Zu viele Versuche",
    "Too many Requests": "Zu viele Versuche",
    "BAD_REQUEST": "Ungültige Anforderung",
    "Bad Request": "Ungültige Anforderung",
    "VIOLATION::IS_BLANK_ERROR": "Dieser Wert sollte nicht leer sein.",
    "IS_BLANK_ERROR": "Dieser Wert sollte nicht leer sein.",
    "CHECKOUT__CUSTOMER_RECOVERY_HASH_EXPIRED": "Der Hash \"{{ hash }}\" is abgelaufen.",
    "The product %s could not be found": "Produkt \"{{ id }}\" konnte nicht gefunden werden.",
    "Payment method PayPal not available": "Zahlungsmethode PayPal ist nicht verfügbar.",
    "shipping-address-blocked": "Lieferungen an Lieferadresse \"{{ value }}\" sind nicht möglich.",
    "product-stock-reached": "Das Produkt \"{{ name }}\" ist in der gewählten Menge nicht verfügbar.",
    "promotion-not-found": "Gutschein mit Code \"{{ promotionCode }}\" nicht gefunden.",
    "promotion-excluded": "Gutschein \"{{ name }}\" wurde für den Warenkorb ausgeschlossen.",
    "promotion-not-eligible": "Gutschein \"{{ name }}\" auf den Warenkorb nicht anwendbar.",
    "promotion-cart-empty": "Rabatt wird angewendet, sobald Sie etwas in den Warenkorb legen.",
    "payment-method-blocked": "Zahlungsart \"{{ name }}\" nicht verfügbar. Grund: nicht erlaubt.",
    "agb-not-checked": "Bitte stimmen Sie unseren \"Allgemeinen Geschäftsbedingungen\" zu.",
    "validation_required": "Pflichtfeld",
    "validation_required_boolean": "Pflichtfeld",
    "validation_email": "Bitte geben Sie eine valide E-Mail-Adresse ein.",
    "validation_min_8": "Mindestens 8 Zeichen lang.",
    "validation_one_lower": "Mindestens ein Kleinbuchstabe",
    "validation_one_upper": "Mindestens ein Großbuchstabe",
    "validation_one_special": "Mindestens ein Sonderzeichen",
    "validation_one_number": "Mindestens ein Zahl",
    "validation_password_confirmation": "Die Passwörter müssen übereinstimmen.",
    "FORBIDDEN": "Zugriff Verboten.",
    "Forbidden": "Zugriff Verboten.",
    "validation_only_digits_allowed": "Nur Ziffern sind erlaubt.",
    "validation_exact_digits": "Eingabe muss genau {{ digits }} Ziffern lang sein.",
    "validation_vat_id": "Bitte geben Sie eine valide Umsatzsteuer-ID ein.",
    "cart_discount": "Gutschein \"{{ name }}\" wurde zu Ihrem Warenkorb hinzugefügt.",
    "from": "ab",
    "per": "je",
    "per_piece": "Stck.",
    "per_piece_long": "Stück",
    "your_products": "Ihr Produkte",
    "bookmarks": "Merkliste",
    "product_number_short": "Artikel-Nr.",
    "session_loggedout_info": "Wir haben Sie wegen Inaktivität zu Ihrer eigenen Sicherheit abgemeldet. Bitte melden sich sich erneut an, um auf alle Funktionen unseres Shops zugreifen zu können.",
    "no_lineItems": "Ihr Warenkorb ist leer!",
    "loading": "Lädt...",
    "minimum_order_value": "Mindestbestellwert von {{ value }} nicht erreicht.",
    "confirm_order": "Bestellübersicht",
    "continue": "Weiter",
    "additional_information": "Zusätzliche Informationen",
    "invoice_address": "Rechnungsadresse",
    "change_invoice_address": "Rechnungsadresse ändern",
    "delivery_address": "Lieferadresse",
    "change_delivery_address": "Lieferadresse ändern",
    "payment_method": "Zahlart",
    "delivery_method": "Versandart",
    "additional_information_description": "Sie möchten uns noch etwas über Ihre Bestellung mitteilen?",
    "summary": "Zusammenfassung",
    "subtotal": "Zwischensumme",
    "shipping_costs": "Versandkosten",
    "shipping_free": "GRATIS",
    "cost_free": "GRATIS",
    "total_net_amount": "Nettobetrag",
    "tax_prefix": "zzgl.",
    "tax_suffix": "Mwst",
    "total": "Gesamtbetrag",
    "agb_read": "Ich habe die AGB gelesen und bin mit ihnen einverstanden.",
    "pay_agreement": "Zahlungpflichtig bestellen",
    "cart": "Warenkorb",
    "back_to_shop": "Zurück zum Shop",
    "clear_selection": "Auswahl für alle aufheben",
    "bookmark_selection": "Artikel auf die Merkliste",
    "remove_selection": "Artikel aus Warenkorb löschen",
    "coupon_code": "Gutschein-Code eingeben",
    "to_checkout": "Weiter zur Kasse",
    "already_bought": "Bereits gekauft",
    "bookmark_item": "Auf die Merkliste",
    "delete_item": "Artikel löschen",
    "compare_item": "Vergleichbare Artikel",
    "add_to_cart": "In den Warenkorb",
    "article": "Artikel",
    "bonus": "Prämie",
    "choose_bonus": "Bitte wählen Sie ihre Prämie aus",
    "is_invoice_address": "Entspricht der Rechnungsadresse",
    "send_to_address": "An diese Adresse senden",
    "add_new_address": "Neue Adresse hinzufügen",
    "submit": "Absenden",
    "vatid": "Umsatzsteuer-ID",
    "up_to_free_shipping": "Nur noch <strong>{ value }</strong> bis zur kostenlosen Lieferung.",
    "finish_heading": "Vielen Dank für Ihre Bestellung!",
    "finish_heading_not_found": "Die angeforderte Bestellung wurde nicht gefunden!",
    "finish_subline": "Wir haben Ihre Bestellung mit der Bestellnummer <strong>{{ orderNumber }}</strong> erhalten.<br>Sie erhalten in Kürze eine Bestätigung per E-Mail.",
    "finish_campaign_offers": "Artikel, die Sie interessieren könnten",
    "finish_to_home": "zur Startseite ({{ seconds }})",
    "finish_to_home_ready": "zur Startseite",
    "cart_pickup_info": "Wir liefern Ihnen Ihre Ware. Abholung ist leider aus organisatorischen Gründen nicht möglich.",
    "company": "Firma",
    "salutation": "Anrede",
    "first_name": "Vorname",
    "last_name": "Nachname",
    "default_billing_address": "Standard-Rechnungsadresse",
    "default_shipping_address": "Standard-Lieferadresse",
    "street_housenumber": "Straße und Hausnummer",
    "zipcode": "Postleitzahl",
    "city": "Stadt",
    "country": "Land",
    "is_default_billing": "Als primäre Rechnungsadresse festlegen",
    "is_default_shipping": "Als primäre Lieferadresse festlegen",
    "newsletter_info": "Abonnieren Sie jetzt den kostenlosen TransPak Newsletter und profitieren Sie von attraktiven Rabattaktionen, wissenswerten Infos, Produktneuheiten & hilfreichen Tipps.",
    "forgot_password": "Passwort vergessen?",
    "forgot_password_additional": "Wir senden Ihnen eine Bestätigungs-E-Mail. Klicken Sie auf den darin enthaltenen Link, um Ihr Passwort zu ändern.",
    "send_email": "E-Mail senden",
    "email_address": "E-Mail",
    "password": "Passwort",
    "login": "Anmelden",
    "your_email_address": "Ihre E-Mail-Adresse",
    "forgot_password_request_success": "Wir haben Ihnen eine Bestätigungs-E-Mail gesendet, sofern die von Ihnen eingegebene E-Mail Adresse registriert ist.",
    "new_password": "Neues Passwort",
    "new_password_confirm": "Passwort Wiederholung",
    "reset_password": "Ein neues Passwort definieren",
    "reset_password_success": "Passwort erfolgreich zurückgesetzt.",
    "reset_password_additional": "Wenn Sie das Passwort für Ihr Konto vergessen haben, können Sie hier ein neues definieren. Wenn Sie das neue Passwort speichern, wird Ihr altes Passwort ungültig.",
    "offcanvas_new_customer_coupon": "Neukunde? 15 % Rabatt<sup>*</sup> auf Ihre erste Bestellung mit dem Code HALLO15",
    "coupon_info": "Maschinen, Geräte & Transporthilfen ausgenommen. Nicht mit anderen Aktionen & Rabatten kombinierbar.",
    "account_order_open_or_transactions_none_open": "Bestellung nicht gefunden oder die Zahlart Ihrer Bestellung wurde bereits bearbeitet und kann nicht mehr geändert werden. Falls Sie noch Fragen haben, wenden Sie sich bitte an unseren Support.",
    "account_order_transactions_none_open": "Die Zahlart Ihrer Bestellung wurde bereits bearbeitet und kann nicht mehr geändert werden. Falls Sie noch Fragen haben, wenden Sie sich bitte an unseren Support.",
    "Invalid username and/or password.": "Ungültiger Benutzername und/oder Kennwort.",
    "An email and password are required to login.": "E-Mail und Passwort werden für die Anmeldung benötigt.",
    "An email and password are required to login": "E-Mail und Passwort werden für die Anmeldung benötigt.",
    "register": "Registrieren:",
    "Invalid file extensions":"Ungültige Dateiendung.",
    "allowed_extensions": "mögliche Dateiformate",
    "allowed_file_size": "maximale Dateigrösse"
}

export default errors
